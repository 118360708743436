
import { defineComponent } from "vue"
import { useStore } from "vuex"

export default defineComponent({
  name: "call-back",
  setup() {
    const store = useStore()

    return {
      userInfo: store.getters.cognitoInfo
    }
  }
})
